import { Box, IconButton, Modal } from '@mui/material';
import { useState } from 'react';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import MessageIcon from '@mui/icons-material/Message';
import { SxStyles } from '@lib/theme/sxTheme';
import { API_URLS } from '@api/internal/constants';
import { getEnv } from '@lib/env/getEnv';
import { useTenantProps } from '@lib/tenants/TenantPropsContext';

export const BlueBoxChatBot: React.FC = () => {
  const [open, setOpen] = useState(false);
  const [hover, setHover] = useState(false);
  const { tenant } = useTenantProps();
  const env = getEnv();
  const blueBoxChatBotUrl =
    tenant.config.setup?.blueBoxChatBotUrl?.[env.client.appEnv];

  if (!blueBoxChatBotUrl) {
    return null;
  }

  return (
    <>
      <IconButton
        color="primary"
        onClick={() => setOpen(true)}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        sx={styles.getValue('iconButton')}
      >
        {hover ? <MessageIcon /> : <ChatBubbleIcon />}
      </IconButton>
      <Modal open={open} onClose={() => setOpen(false)}>
        <Box sx={styles.getValue('modalBox')}>
          <iframe
            src={API_URLS.chatbot}
            width="100%"
            height="100%"
            style={{ border: 'none' }}
            title="Chatbot Iframe"
          ></iframe>
        </Box>
      </Modal>
    </>
  );
};

const styles = new SxStyles({
  modalBox: {
    position: 'fixed',
    bottom: '20px',
    right: '20px',
    maxWidth: '90vw',
    width: 600,
    height: '80vh',
    bgcolor: 'transparent',
  },
  iconButton: {
    position: 'fixed',
    bottom: '50px',
    right: '50px',
    background: 'white',
    borderRadius: '50%',
    boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.1)',
    padding: '10px',
  },
});
